<template>
    <div class="mainbox">
        <div>
            <div @click="goNext(`/gxGathers/school/enrollmentGuide?enrollId=${item.id}&id=${item.schoolId}`)"
                v-for="(item, index) in majorList" :key="index" class="c_item">
                <div style="display: flex;">
                    <div class="mojor_img">
                        <img :src="item.cover" alt="">
                    </div>
                    <div class="mojor_detail">
                        <div class="mojor_til">{{ item.name }} <img
                                style="width: 10px;height: auto;margin-left: 30px;margin-right: 5px;"
                                src="~@/assets/img/TechnicalCollege/81.png" alt="">
                            <span>{{ item.areaProvinceName }}</span>
                        </div>
                        <div class="mojor_item">
                            <div>
                                <div>招生对象：</div>
                                <div style="width: 120px;" class="elp">{{ item.enrollObject }}</div>
                            </div>
                            <div>
                                <div>所获学历：</div>
                                <div style="width: 155px;" class="elp">{{ item.majorStage }}</div>
                            </div>
                            <div>
                                <div>招生计划：</div>
                                <div style="width: 120px;" class="elp">{{ item.enrollPlan }} 人</div>
                            </div>
                            <div>
                                <div>学费：</div>
                                <div style="width: 120px;" class="elp">{{ item.feeNumAll }}</div>
                            </div>
                            <div>
                                <div>简介：</div>
                                <div style="width: 640px;height: 20px;" v-html="ImgSizeLimit(item.detial)" class="elp">
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div class="mojor_btn">
                    <div>查看详情</div>

                </div>
            </div>
            <el-empty v-if="!majorList || majorList.length == 0" description="暂无数据"></el-empty>


        </div>
        <el-pagination style="display: flex; justify-content: center" class="pagination partner-pagination"
            prev-text="上一页" next-text="下一页" :current-page="pageNum" :page-size="pageSize"
            :page-sizes="[10, 20, 50, 100]" layout="total, sizes, prev, pager, next" :total="total" :page-count="4"
            @current-change="handleCurrentChange" @size-change="handleSizeChange" />

    </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
import { getDomain } from "@/api/cookies";
import searchs from "../components/search.vue";
import connects from "./connect.vue";

export default {
    components: { searchs, connects },
    props: {
        chooseList: [],
        majorName: ''
    },
    data() {
        return {
            is_huadong: true,
            pageSize: 12,
            pageNum: 1,
            total: 0,
            majorList: []
        };
    },
    created() {
        // this.detail_id = this.$route.query.id;
        this.getList();
    },
    mounted() { },
    watch: {
        chooseList: {
            deep: true,
            handler(val) {
                this.getList()
            },
        },
        majorName: {
            deep: true,
            handler(val) {
                this.getList()
            },
        },
    },
    methods: {
        handleSizeChange(val) {
            this.pageNum = 1;
            this.pageSize = val;
            this.getList();
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getList();
        },
        getList() {
            let majorStage = ''
            let minNumber = ''
            let maxNumber = ''
            let enrollObject = ''
            let areaProvince = ''
            let schoolTypeId = ''
            let characteristic = ''
            if (this.chooseList && this.chooseList.length > 0) {
                this.chooseList.forEach(e => {
                    if (e.sname == 'majorStageList') {
                        majorStage = e.id
                    } else if (e.sname == 'enrollObjectList') {
                        enrollObject = e.id
                    } else if (e.sname == 'feeNumList') {
                        minNumber = e.minNumber
                        maxNumber = e.maxNumber
                    } else if (e.sname == 'areaList') {
                        areaProvince = e.id
                    } else if (e.sname == 'schoolTypeList') {
                        schoolTypeId = e.id
                    } else if (e.sname == 'characteristicList') {
                        characteristic = e.id
                    }
                });
            }
            know.selectSchoolBriefsPcAllPage({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                projectType: 0,
                majorStage: majorStage ? majorStage : undefined,
                enrollObject: enrollObject ? enrollObject : undefined,
                areaProvince: areaProvince ? areaProvince : undefined,
                schoolTypeId: schoolTypeId ? schoolTypeId : undefined,
                characteristic: characteristic ? characteristic : undefined,
                minNumber: minNumber ? minNumber : undefined,
                maxNumber: maxNumber ? maxNumber : undefined,
            })
                .then((res) => {
                    this.majorList = res.rows
                    this.total = res.total
                });
        },

    },
};
</script>

<style lang="less" scoped>
.mainbox {
    background: #FFFFFF;
    border-radius: 3px 3px 3px 3px;
    margin-top: 20px;

    >div {
        display: block;

        .c_item {
            padding: 30px;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            cursor: pointer;
            border-bottom: 1px solid #EEEEEE;

            .mojor_img {
                width: 130px;
                height: 130px;
                border-radius: 8px 8px 8px 8px;
                overflow: hidden;
            }

            .mojor_detail {
                width: 710px;
                height: 130px;
                margin-left: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .mojor_til {
                    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei-Bold;
                    font-weight: bold;
                    font-size: 20px;
                    color: #333333;
                    margin-bottom: 10px;
                    display: flex;
                    align-items: center;

                    span {

                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 12px;
                        color: #666666;
                    }
                }

                .mojor_item {
                    display: flex;
                    flex-wrap: wrap;

                    >div {
                        display: flex;
                        font-family: MicrosoftYaHei, MicrosoftYaHei;
                        font-weight: normal;
                        font-size: 14px;
                        color: #666666;
                        margin-right: 20px;
                        margin-top: 14px;

                        >div:nth-child(1) {
                            flex-shrink: 0;
                            color: #555555;
                            margin-right: 5px;
                            font-weight: bold;
                        }
                    }

                }


            }

            .mojor_btn {
                height: 90px;
                border-left: 1px solid #EEEEEE;
                display: flex;
                align-items: center;

                >div {
                    width: 112px;
                    height: 40px;
                    background: #1061FF;
                    border-radius: 20px 20px 20px 20px;
                    font-family: MicrosoftYaHei, MicrosoftYaHei;
                    font-weight: normal;
                    font-size: 14px;
                    color: #FFFFFF;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-left: 34px;

                    &:hover {
                        background: #3D6CC8;
                    }
                }


            }

            &:hover {
                background: rgba(16, 97, 255, 0.1);

                .mojor_til {
                    color: #3D6CC8;
                }
            }
        }


    }


}

img {
    width: 100%;
    height: 100%;
    display: block;
}
</style>